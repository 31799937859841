import styled from "styled-components";
import media from "styled-media-query";
import { Link } from "gatsby";

export const SectionWrapper = styled.section`
  padding: var(--default-padding-desktop);
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;
export const SectionTextWrapper = styled.section`
  padding: var(--default-padding-desktop);
  height: auto;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;
export const SectionContent = styled.div`
  text-align: center;
  h1 {
    font-size: var(--large-title-size);
    color: var(--esx-red);
    font-weight: bold;
  }
  h2 {
    font-size: var(--subtitle-size);
  }
`;
export const SectionLink = styled(Link)`
  color: var(--esx-red);
  &:hover {
    color: var(--esx-red);
  }
`;
