import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import * as S from "../components/SuccessAndNotFound/styled";

const SuccessJobsPage = () => (
  <Layout>
    <SEO title="Candidatura enviada" />
    <S.SectionWrapper>
      <S.SectionContent>
        <h1>Candidatura enviada!</h1>
        <h2>
          Obrigado pelo interesse em fazer parte do time ESX! Em breve lhe
          enviaremos um e-mail de confirmação.{" "}
        </h2>
      </S.SectionContent>
      <S.SectionLink to="/">Voltar para a página inicial</S.SectionLink>
    </S.SectionWrapper>
  </Layout>
);

export default SuccessJobsPage;
